<template>
    <div class="card ribbon-box border shadow-none mb-lg-0" style="margin-bottom:0;">
      <div class="card-header align-items-center d-flex card-warning">
        <h4 class="card-title mb-0 flex-grow-1">{{ $t("t-bot-strategy2-name") }} (Spot)</h4>
      </div>
      <div class="card-body text-muted">
        <h5 class="fs-14 mb-3">{{ $t("t-bot-strategy2-income") }}</h5>
        <ul class="mb-0">
          <li>{{ $t("t-bot-strategy2-fact1") }}</li>
          <li>{{ $t("t-bot-strategy2-fact2") }}</li>
          <li>{{ $t("t-bot-strategy2-fact3") }}</li>
          <li>{{ $t("t-bot-strategy2-fact4") }}</li>
        </ul>
        <br>
        <div class="align-middle text-center">
          <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#add-bot"
                  @click="selectStrategy">
            <i class=" ri-add-circle-line me-1 align-bottom"></i>
            {{ $t("t-bot-choose-button") }}
          </button>
        </div>
      </div>
    </div>
</template>
<style>
.ribbon-three::after {
  border-left: 64px solid transparent;
  border-right: 64px solid transparent;
}

.ribbon-three > span {
  width: 130px;
}
</style>
<script>
import {localeComputed} from "@/state/helpers";


export default {
  data() {
    return {
      predefine_strategy: 2,
      rows: [],
    };
  },
  components: {
    ...localeComputed
  },

  methods: {
    selectStrategy() {
      this.$emit('select-strategy');
    },
    currentLocale() {
      return this.$i18n.locale;
    },
  },
  computed: {
  },
};
</script>
