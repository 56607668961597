<template>
  <form v-on:submit.prevent="submit">
    <div class="row g-3">
      <div class="col-xxl-12">
        <div>
          <label for="name" class="form-label">{{ $t('t-bot-bot-name') }}</label>
          <input required type="text" class="form-control" id="name" :placeholder='$t("t-example") + " My trading bot John"' v-model="form.title"/>
        </div>
      </div>
      <div class="col-xxl-12">
        <label for="api_key_id" class="form-label">{{ $t('t-api-key-title') }}</label>
        <select
            required
            class="form-select mb-3"
            aria-label="Default select example"
            v-model="form.exchange_connection_id"
            @change="defineCurrency($event)"
        >
          <option value="">{{ $t('t-bot-link-your-api-key') }}</option>
          <option v-for="(credential) of credentials" :key="credential.$id" :value="credential.$id" :data-type="credential.type">{{credential.title}}</option>
        </select>
      </div>

      <div class="col-xxl-12">
        <div>
          <label for="first_order_amount" class="form-label">{{ $t('t-bot-first-order-amount') }} {{baseCurrency}}</label>
          <input type="number" required class="form-control" min="20" id="first_order_amount" :placeholder='$t("t-example") + " 20"' v-model="form.first_order_amount" />
          <div class="form-text">
            {{ $t('t-minimum') }}: 20
          </div>
        </div>
      </div>
      <div class="col-xxl-12">
        <div>
          <label for="take-profit" class="form-label">{{ $t('t-bot-active-deals') }}</label>
          <input type="number" required class="form-control" id="take-profit" max="13" :placeholder='$t("t-example") + " 5"' v-model="form.active_deals" />
        </div>
      </div>
      <div class="col-xxl-12">
        <div>
          <label for="take-profit" class="form-label">{{ $t('t-bot-required-balance') }} {{baseCurrency}}</label>
          <input type="text" disabled class="form-control" id="take-profit"  placeholder="" :value="requiredBalance" />
        </div>
      </div>

      <!--end col-->
      <div class="col-lg-12">
        <div class="hstack gap-2 justify-content-end">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">{{ $t('t-close-button') }}</button>
          <button type="submit" class="btn btn-primary">{{ $t('t-create-button') }}</button>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </form>
</template>

<script>
import ExchangeConnection from "@/state/entities/exchangeConnection";
import BotService from "@/services/bot-service";
import Bot from "@/state/entities/bot";
import Swal from "sweetalert2";

export default {
  props: ['predefine_strategy'],
  data() {
    return {
      form: {
        title: '',
        exchange_connection_id: '',
        first_order_amount: '',
        take_profit_type: 'percentage',
        active_deals: '',
        base_currency: 'USDT'
      }
    }
  },
  computed: {
    requiredBalance() {
      if(!this.form.active_deals || !this.form.first_order_amount){
        return "";
      }
      if(this.predefine_strategy === 1) {
        return this.form.active_deals * this.form.first_order_amount * (5+1);
      }
      if(this.predefine_strategy === 2) {
        return this.form.active_deals * this.form.first_order_amount * (2+1);
      }
      if(this.predefine_strategy === 3) {
        return this.form.active_deals * this.form.first_order_amount;
      }
      return '';
    },
    credentials() {
      return ExchangeConnection.all().filter((credential) => {
        if(this.predefine_strategy === 1 || this.predefine_strategy === 2) {
          return credential.type === "binance-spot" || credential.type === "bitget-spot"
        }
        if(this.predefine_strategy === 3) {
          return credential.type === "binance-futures" || credential.type === "bitget-futures"
        }
      });
    },
    baseCurrency() {
      return this.form.base_currency;
    },
  },
  components: {},
  async mounted() {
    /** @TODO call via dispatch store */
    (await ExchangeConnection.api().get('/exchange-connection'));
  },
  methods: {
    defineCurrency() {
      this.form.base_currency = 'USDT';
    },
    submit() {
      const payload = { ...this.form };
      payload.exchange_connection_id = parseInt(payload.exchange_connection_id);
      payload.first_order_amount = parseInt(payload.first_order_amount);
      payload.active_deals = parseInt(payload.active_deals);
      payload.take_profit_type = 'percentage_from_total_volume'
      payload.base_currency = this.baseCurrency;
      if(this.predefine_strategy === 1) {
        payload.preset = 'conservative';
      }
      if(this.predefine_strategy === 2) {
        payload.preset = 'moderate';
      }
      if(this.predefine_strategy === 3) {
        payload.preset = 'aggressive';
      }

      if(this.predefine_strategy === 1 || this.predefine_strategy === 2) {
        payload.take_profit = 4;
        payload.enable_safety_orders = true;
        if (this.predefine_strategy === 1) {
          payload.safety_order_percentage = 10;
          payload.max_safety_orders = 5;
        }else if (this.predefine_strategy === 2) {
          payload.safety_order_percentage = 5;
          payload.max_safety_orders = 4;
        }
      } else {
        payload.take_profit = 2;
        payload.enable_safety_orders = false;
        payload.max_safety_orders = null;
      }

      payload.safety_order_amount = payload.first_order_amount;
      payload.stop_lose_percentage = null;
      BotService.create(payload).then((response) => {
        this.$emit('created');
        if(response.status === 201) {
          (Bot.api().get('/bot'));
          Swal.fire({
            title: "Bot successfully created.",
            icon: 'success',
            timer: 3000,
            timerProgressBar: true,
          });
          /** @TODO: error handling.Add message from server */
          console.log(response);
        } else {
          Swal.fire({
            title: this.$t("t-default-error-response-message"),
            icon: 'error',
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }).catch((er) => {
        console.warn(er);
        /** @TODO: error handling.Add message from server */
        Swal.fire({
          title: this.$t("t-default-error-response-message"),
          icon: 'error',
          timer: 3000,
          timerProgressBar: true,
        });
      });
    },
  },
};
</script>
